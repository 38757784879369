'use client'

import { NavBar, Route } from '@ethena/shared-ui/src/components/NavBar/NavBar'
import { Socials } from '@ethena/shared-ui/src/components/Socials'
import { SocialsButton } from '@ethena/shared-ui/src/components/SocialsButton'
import { Stats } from '@ethena/shared-ui/src/components/Stats'
import { DISCORD_LINK } from '@ethena/shared-ui/src/constants/socials'
import { useScrollbarWidth } from '@ethena/shared-ui/src/hooks/useScrollbarWidth'

import clsx from 'clsx'
import { useState } from 'react'

import { IS_GERMAN } from '@/constants'
import useUserStore from '@/store/user-store'

const NavBarRight = () => {
  const [isSocialsOpen, setIsSocialsOpen] = useState(false)

  const { isIdle } = useUserStore()

  return (
    <div className="sm:flex hidden">
      {!IS_GERMAN && (
        <div
          className={clsx('çtransition-all ease-in duration-150', {
            'opacity-0 translate-x-1/2': isSocialsOpen,
            'opacity-100': !isSocialsOpen,
          })}
        >
          <Stats isIdle={isIdle} />
        </div>
      )}
      <SocialsButton isOpen={isSocialsOpen} setIsOpen={setIsSocialsOpen} />
    </div>
  )
}

const NavBarBottomMobile = () => {
  const { isIdle } = useUserStore()

  return (
    <div className="mt-4 flex flex-col gap-2">
      <Socials className="sm:hidden w-full justify-around p-2 pb-4" />
      {!IS_GERMAN && <Stats isIdle={isIdle} />}
    </div>
  )
}

export function LandingWrapper({ children }: { children: React.ReactNode }) {
  const scrollbarWidth = useScrollbarWidth()

  return (
    <div
      className={clsx(
        'flex min-h-[calc(100vh-20px)] sm:min-h-[calc(100vh-40px)] flex-col rounded-4xl mx-2.5 sm:mx-[30px] my-2.5 sm:my-5 border-2 border-cblue-600 relative',
        `w-[calc(100vw - ${scrollbarWidth}px] max-w-[2000px] 4xl:mx-auto`,
        'layout-background-image',
      )}
    >
      <NavBar
        margin="mx-4 mt-6 hbase:mt-8 mb-2 sm:mx-6"
        routes={
          [
            { cutToMenuBreakpoint: 'md:block', route: '/', title: 'Home' },
            ...(!IS_GERMAN ? [{ cutToMenuBreakpoint: 'md:block', route: '/ecosystem', title: 'Ecosystem' }] : []),
            ...(IS_GERMAN
              ? [
                  {
                    cutToMenuBreakpoint: 'md:block',
                    route: '',
                    routes: [
                      {
                        route: 'https://app.ethena.fi/dashboards/positions',
                        title: 'Collateral',
                      },
                      {
                        route: '/audit-report',
                        title: 'Audit Report',
                      },
                      {
                        route: '/significant-events',
                        title: 'Significant Events',
                      },
                    ],
                    title: 'USDe Holder Info',
                  },
                  {
                    cutToMenuBreakpoint: 'lg:block',
                    route: 'https://app.ethena.fi/buy',
                    title: 'Buy USDe',
                  },
                ]
              : []),
            {
              cutToMenuBreakpoint: 'lg:block',
              route: 'https://ethena-labs.gitbook.io/ethena-labs/',
              title: 'Documentation',
            },
            ...(!IS_GERMAN
              ? [
                  {
                    cutToMenuBreakpoint: 'md:block',
                    route: '',
                    routes: [
                      { route: DISCORD_LINK, title: 'Discord' },
                      { route: '/leaderboard', title: 'Leaderboard' },
                      { route: '/referrals', title: 'Referrals' },
                      { route: 'https://gov.ethenafoundation.com/', title: 'Governance' },
                    ],
                    title: 'Community',
                  },
                ]
              : []),
          ] as Route[]
        }
        collapseBreakpoint="md:hidden"
        right={<NavBarRight />}
        bottomMobile={<NavBarBottomMobile />}
        mobileMenuSize="w-[calc(100vw-60px)] sm:w-[calc(100vw-110px)] md:w-[calc(100vw-110px)] h-[calc(100vh-130px)] sm:h-[calc(100vh-200px)]"
      />
      {children}
    </div>
  )
}
